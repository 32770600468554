<template>
  <div class="container">
    <h1>排名结果</h1>
    <div class="rankings-container">
      <div class="ranking-section">
        <h2>个人赛排名</h2>
        <table class="ranking-table">
          <thead>
            <tr>
              
              <th>编号</th>
              <th>运动员名称</th>
              <th>成绩</th>
              <th>排名</th>
              <!-- <th>奖项</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(score, index) in individualRankings" :key="index" :class="getIndividualAwardClass(index)">
              <td>{{ score.code }}</td>
              <td>{{ score.athleteName }}</td>
              <td>{{ score.totalScore }}</td>
              <td>{{ index + 1 }}</td>
              <!-- <td>{{ getIndividualAward(index) }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div class="ranking-section">
        <h2>团队赛排名</h2>
        <table class="ranking-table">
          <thead>
            <tr>
              <th>编号</th>
              <th>单位</th>
              <th>成绩</th>
              <th>排名</th>
              <!-- <th>奖项</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(score, index) in teamRankings" :key="index" :class="getTeamAwardClass(index)">
              <td>{{ score.code }}</td>
              <td>{{ score.name }}</td>
              <td>{{ score.totalScore }}</td>
              <td>{{ index + 1 }}</td>
              <!-- <td>{{ getTeamAward(index) }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button @click="$router.push({ name: 'InputScores2' })" class="btn back-btn">返回录入页面</button>
  </div>
</template>

<script>
export default {
  name: 'ScoreRankings2',
  props: ['teamScores','individualScores'],
  data() {
    return {
      localIndividualScores: this.individualScores
        .slice() // 创建一个副本以防止直接修改prop
        .sort((a, b) => b.score - a.score),
      localTeamScores: this.teamScores
        .slice() // 创建一个副本以防止直接修改prop
        .sort((a, b) => b.score - a.score)
    };
  },
  computed: {
    individualRankings() {
      return this.localIndividualScores;
    },
    teamRankings() {
      return this.localTeamScores;
    }
  },
  methods: {
    getIndividualAward(index) {
      if (index < 3) return '一等奖';
      if (index < 9) return '二等奖';
      if (index < 21) return '三等奖';
      return '';
    },
    getTeamAward(index) {
      if (index < 1) return '一等奖';
      if (index < 4) return '二等奖';
      if (index < 9) return '三等奖';
      return '';
    },
    getIndividualAwardClass(index) {
      if (index < 3) return 'first-place highlighted';
      if (index < 9) return 'second-place';
      if (index < 21) return 'third-place';
      return '';
    },
    getTeamAwardClass(index) {
      if (index < 1) return 'first-place highlighted';
      if (index < 4) return 'second-place';
      if (index < 9) return 'third-place';
      return '';
    }
  }
}
</script>

<style>
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 28px;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.rankings-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.ranking-section {
  flex: 1;
}

.ranking-section h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.ranking-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.ranking-table th, .ranking-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 18px; /* 设置字体大小为 18px */
}

.ranking-table th {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #333;
}
/* 定义闪烁动画 */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.highlighted {
  animation: blink 1s infinite alternate; /* 定义闪烁动画 */
}
.first-place {
  background-color: gold;
  font-weight: bold;
}

.second-place {
  background-color: silver;
}

.third-place {
  background-color: #cd7f32;
}

.back-btn {
  background-color: #007bff;
  display: block;
  margin: 20px auto;
  color: white;
}
</style>
