<template>
  <div class="container">
    <h1>当前比赛序号</h1>
    <div class="tables-container">
      <div class="table-section">
        <h2>团队赛比赛顺序</h2>
        <table class="sequence-table">
          <thead>
            <tr>
              <th>序号</th>
              <th>比赛日</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(team, index) in teamSequence" :key="team.id" :class="getCurrentClass(index, 'team')">
              <td>{{ team.id }}</td>
              <td>{{ team.day }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-section">
        <h2>个人赛比赛顺序-A组</h2>
        <table class="sequence-table">
          <thead>
            <tr>
              <th>5日 A组</th>
              <!-- <th>5日 B组</th> -->
              <th>6日 A组</th>
              <!-- <th>6日 B组</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in individualSequence" :key="rowIndex">
              <td :class="getCurrentClass(rowIndex, 'individual', 5)">{{ row.a5 }}</td>
              <!-- <td :class="getCurrentClass(rowIndex, 'individual', 5)">{{ row.b5 }}</td> -->
              <td :class="getCurrentClass(rowIndex, 'individual', 6)">{{ row.a6 }}</td>
              <!-- <td :class="getCurrentClass(rowIndex, 'individual', 6)">{{ row.b6 }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
        <div class="table-section">
        <h2>个人赛比赛顺序-B组</h2>
        <table class="sequence-table">
          <thead>
            <tr>
              <!-- <th>5日 A组</th> -->
              <th>5日 B组</th>
              <!-- <th>6日 A组</th> -->
              <th>6日 B组</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in individualSequence2" :key="rowIndex">
              <!-- <td :class="getCurrentClass(rowIndex, 'individual', 5)">{{ row.a5 }}</td> -->
              <td :class="getCurrentClass(rowIndex, 'individual2', 5)">{{ row.b5 }}</td>
              <!-- <td :class="getCurrentClass(rowIndex, 'individual', 6)">{{ row.a6 }}</td> -->
              <td :class="getCurrentClass(rowIndex, 'individual2', 6)">{{ row.b6 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="button-container">
      <button @click="nextTeamSequence" class="btn team-btn">下一团队序号</button>
      <button @click="nextIndividualSequence" class="btn individual-btn">下一个人序号-A组</button>
      <button @click="nextIndividualSequence2" class="btn individual-btn">下一个人序号-B组</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTeamIndex: 0,
      currentIndividualIndex: { day: 5, index: 0 },
      currentIndividualIndex2: { day: 5, index: 0 },
      teamSequence: [
        { id: 'A1', day: '5日' }, { id: 'A2', day: '' }, { id: 'A3', day: '' }, { id: 'A4', day: '' }, { id: 'A5', day: '' },
        { id: 'A6', day: '' }, { id: 'A7', day: '' }, { id: 'B1', day: '' }, { id: 'B2', day: '' }, { id: 'B3', day: '' },
        { id: 'B4', day: '' }, { id: 'B5', day: '6日' }, { id: 'B6', day: '' }, { id: 'B7', day: '' }, { id: 'B8', day: '' },
      ],
      individualSequence: [
        { a5: 'a501',  a6: 'a601',  }, { a5: 'a502',  a6: 'a602', },
        { a5: 'a503',  a6: 'a603',  }, { a5: 'a504',  a6: 'a604',  },
        { a5: 'a505',  a6: 'a605', }, { a5: 'a506',  a6: 'a606', },
        { a5: 'a507', a6: 'a607', }, { a5: 'a508',  a6: 'a608', },
        { a5: 'a509', a6: 'a609',  }, { a5: 'a510',  a6: 'a610', },
        { a5: 'a511', a6: 'a611',  }, { a5: 'a512', a6: '',  },
      ],
      individualSequence2: [
        {  b5: 'b501',  b6: 'b601' }, { b5: 'b502', b6: 'b602' },
        {  b5: 'b503',  b6: 'b603' }, { b5: 'b504', b6: 'b604' },
        {  b5: 'b505',  b6: 'b605' }, { b5: 'b506', b6: 'b606' },
        {  b5: 'b507',  b6: 'b607' }, { b5: 'b508', b6: 'b608' },
        {  b5: 'b509',  b6: 'b609' }, { b5: 'b510', b6: 'b610' },
        {  b5: 'b511',  b6: 'b611' }, { b5: 'b512',  b6: '' },
      ],
    };
  },
  methods: {
    nextTeamSequence() {
      if (this.currentTeamIndex < this.teamSequence.length - 1) {
        this.currentTeamIndex++;
      } else {
        this.currentTeamIndex = 0;
      }
    },
    nextIndividualSequence() {
      if (this.currentIndividualIndex.index < 11) {
        this.currentIndividualIndex.index++;
      } else if (this.currentIndividualIndex.day === 5) {
        this.currentIndividualIndex.day = 6;
        this.currentIndividualIndex.index = 0;
      } else {
        this.currentIndividualIndex.day = 5;
        this.currentIndividualIndex.index = 0;
      }
    },
    nextIndividualSequence2() {
      if (this.currentIndividualIndex2.index < 11) {
        this.currentIndividualIndex2.index++;
      } else if (this.currentIndividualIndex2.day === 5) {
        this.currentIndividualIndex2.day = 6;
        this.currentIndividualIndex2.index = 0;
      } else {
        this.currentIndividualIndex2.day = 5;
        this.currentIndividualIndex2.index = 0;
      }
    },
    getCurrentClass(index, type, day = '') {
      if (type === 'team' && index === this.currentTeamIndex) {
        return 'highlighted';
      }
      if (type === 'individual' &&
          index === this.currentIndividualIndex.index &&
          day === this.currentIndividualIndex.day) {
        return 'highlighted';
      }
      if (type === 'individual2' &&
          index === this.currentIndividualIndex2.index &&
          day === this.currentIndividualIndex2.day) {
        return 'highlighted';
      }
      return '';
    }
  }
};
</script>

<style>
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 28px;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.tables-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.table-section {
  flex: 1;
}

.sequence-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.sequence-table th, .sequence-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  /* font-size: 20px; 增加字体大小 */
}

.sequence-table th {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #333;
}

.highlighted {
  animation: blink 1s infinite alternate;
  background-color: #ffeb3b;
  font-size: 48px; /* 调整字体大小 */
  font-weight: bold;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}

.team-btn {
  background-color: #28a745;
}

.individual-btn {
  background-color: #17a2b8;
}
</style>
