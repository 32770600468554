<template>
  <div class="container">

   
   
  <h1>录入成绩</h1>
    <form @submit.prevent="addScore" class="input-form">
      <div class="score-row" style="width:50%">
        <div class="radio-group">
          <label>
            <input type="radio" v-model="competitionType" value="individual" @change="validateTimeSettings"> 个人项目
          </label>
          <label>
            <input type="radio" v-model="competitionType" value="team1" @change="validateTimeSettings"> 团队项目1
          </label>
          <label>
            <input type="radio" v-model="competitionType" value="team2" @change="validateTimeSettings"> 团队项目2
          </label>
          <label>
            <input type="radio" v-model="competitionType" value="team3" @change="validateTimeSettings"> 团队项目3
          </label>
          <label>
            <input type="radio" v-model="competitionType" value="team4" @change="validateTimeSettings"> 团队项目4
          </label>
          <label>
            <input type="radio" v-model="competitionType" value="team5" @change="validateTimeSettings"> 团队项目5
          </label>
        </div>
      </div>

      <div v-if="competitionType">

        <div class="score-row" style="display: flex;width:50%">
          <label style="width: 120px;">编号:</label>
          <input v-model="code" type="text" class="input-field" placeholder="请输入编号">
        </div>

        <!-- <div class="score-row"  style="display: flex;width:50%" v-if="competitionType === 'team'">
          <label style="width: 120px;">单位:</label>
          <input v-model="name" type="text" class="input-field" placeholder="请输入单位">
        </div>

        <div class="score-row"  style="display: flex;width:50%" v-if="competitionType === 'individual'">
          <label style="width: 120px;">运动员名称:</label>
          <input v-model="athleteName" type="text" class="input-field" placeholder="请输入运动员名称">
        </div>

        <div class="score-row" style="display: flex;width:50%">
          <label style="width: 120px;">理论成绩:</label>
          <input v-model="theoryScore" type="number" min="0" max="360" class="input-field" placeholder="请输入理论成绩">
        </div> -->

        <div v-if="competitionType === 'team1'" >
          <h3>激光点云数据采集</h3>
         
          <div  >
            <table class="deductions-table">
            <thead>
              <tr>
                <th>评价项目</th>
                <th>质量要求</th>
                <th>评分标准</th>
                <th style="width:85px">扣分</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">准备工作-航前检查</td>
                <td>进行飞行前机体外观检查，并向裁判组汇报“已完成飞行前机体外观检查”</td>
                <td>未开展检查并汇报扣3分</td>
                <td><input type="checkbox" v-model="deductionsCollection.preFlightCheck1" class="large-checkbox"> (-3分)</td>
              </tr>
              <tr>
                <td>正确进行飞行器遥控器和机体连接检查，并向裁判组汇报“已完成飞行器遥控器和机体连接检查”</td>
                <td>未开展检查并汇报扣3分</td>
                <td><input type="checkbox" v-model="deductionsCollection.preFlightCheck2" class="large-checkbox"> (-3分)</td>
              </tr>
              <tr>
                <td>未检查飞行安全策略及各项自检情况，并向裁判组汇报“已完成飞行安全策略及各项自检情况”</td>
                <td>未开展检查并汇报扣3分</td>
                <td><input type="checkbox" v-model="deductionsCollection.preFlightCheck3" class="large-checkbox"> (-3分)</td>
              </tr>
              <tr>
                <td>安全文明-选手入场</td>
                <td>着装整齐、符合规范，精神面貌良好</td>
                <td>
                  1. 未穿工作服扣1分<br>
                  2. 未系戴安全帽或系戴错误，扣2分
                </td>
                <td>
                  <input type="checkbox" v-model="deductionsCollection.dressCode1" class="large-checkbox"> (-1分)<br>
                  <input type="checkbox" v-model="deductionsCollection.dressCode2" class="large-checkbox"> (-2分)
                </td>
              </tr>
              <tr>
                <td>安全文明-进入指定区域</td>
                <td>参赛队伍在检录区检录并完成分工抽签工作，获得现场裁判指令后进入指定飞行操作区</td>
                <td>未向现场裁判申请进入指定操作区(功能自检完成后)，扣3分</td>
                <td><input type="checkbox" v-model="deductionsCollection.enterZone" class="large-checkbox"> (-3分)</td>
              </tr>
              <tr>
                <td >安全文明-工作汇报</td>
                <td>工作汇报</td>
                <td>
                  1. 未向裁判报告准备完成申请起飞，扣1分<br>
                  2. 飞行结束后未向裁判汇报工作结束，扣1分
                </td>
                <td>
                  <input type="checkbox" v-model="deductionsCollection.workReport1" class="large-checkbox"> (-1分)<br>
                  <input type="checkbox" v-model="deductionsCollection.workReport2" class="large-checkbox"> (-1分)
                </td>
              </tr>
              <tr>
                <td >安全文明-选手离场</td>
                <td>文明飞行，有序离场</td>
                <td>
                  1. 飞行结束后未及时向裁判申请、拷贝飞行数据，扣1分<br>
                  2. 工作结束后场地清理不整洁，存在遗留物等，扣1分
                </td>
                <td>
                  <input type="checkbox" v-model="deductionsCollection.orderlyDeparture1" class="large-checkbox"> (-1分)<br>
                  <input type="checkbox" v-model="deductionsCollection.orderlyDeparture2" class="large-checkbox"> (-1分)
                </td>
              </tr>
              <tr>
                <td >安全文明-无人机飞行安全</td>
                <td>无人机飞行安全</td>
                <td>
                  1. 飞行过程中操控手未在操作区操作无人机飞行，扣10分<br>
                  2. 无人机未在起降区起飞，扣5分<br>
                  3. 无人机未在起降区降落或压线、降落在起降区域外，扣5分
                </td>
                <td>
                  <input type="checkbox" v-model="deductionsCollection.uavSafety1" class="large-checkbox"> (-10分)<br>
                  <input type="checkbox" v-model="deductionsCollection.uavSafety2" class="large-checkbox"> (-5分)<br>
                  <input type="checkbox" v-model="deductionsCollection.uavSafety3" class="large-checkbox"> (-5分)
                </td>
              </tr>
              <tr>
                <td>比赛用时</td>
                <td colspan="2">
                  <!-- 比赛用时按公式计算得分 -->
                </td>
                <td><input v-model="deductionsCollection.timeScore"  class="input-field" placeholder="请输入用时"></td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>

        <!-- 激光点云数据分析 -->
        <div v-if="competitionType === 'team2'">
          <h3>激光点云数据分析</h3>
          <div>
            <table class="deductions-table">
              <thead>
                <tr>
                  <th>评价项目</th>
                  <th>质量要求</th>
                  <th>评分标准</th>
                  <th style="width:85px">扣分</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>准备工作-数据处理</td>
                  <td>按照裁判要求有序进场进行项目1数据处理，该项目不计时</td>
                  <td>参赛队伍未获得现场裁判指令私自进入指定操作区，扣5分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.dataProcessing" class="large-checkbox"> (-5分)</td>
                </tr>
                <tr>
                  <td rowspan="2">点云质量-激光点云数据质量满足国网标准</td>
                  <td>完整采集杆塔周边50米、边导线外侧50米范围内点云数据</td>
                  <td>未完成扣15分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.cloudDataQuality1" class="large-checkbox"> (-15分)</td>
                </tr>
                <!-- <tr>
                  <td>每平方米至少包含100个点，点密度一般控制在100-150点/平方米之间</td>
                  <td>未完成扣10分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.cloudDataQuality2" class="large-checkbox"> (-10分)</td>
                </tr> -->
                <tr>
                  <td>杆塔点云数据结构完整，输电导线点云不存在长距离断缺，导线点完整、均匀</td>
                  <td>未完成扣10分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.cloudDataQuality3" class="large-checkbox"> (-10分)</td>
                </tr>
                <!-- <tr>
                  <td>数据分析质量-点云数据分析</td>
                  <td>由裁判指定1基杆塔进行杆塔呼高、档距、三相相间距离测量，截图存档</td>
                  <td>1.距离测量误差每超±10cm扣5分<br>
                    2.少一处测量点扣10分<br>
                    3.此项工作应在5分钟内完成，每超10秒扣5分
                  </td>
                  <td><input type="number" v-model="deductionsAnalysis.dataAnalysis1" class="input-field"  min="0" max="40"  placeholder="填写扣分，最多扣40分"><br>
                    <span style="font-size:10px;" >填写扣分，最多扣40分</span>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td>每少一处测量点扣10分</td>
                  <td>少一处测量点扣10分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.dataAnalysis2" class="large-checkbox"> (-10分)</td>
                </tr>
                <tr>
                  <td>在5分钟内完成，每超10秒扣5分</td>
                  <td>超时扣分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.dataAnalysis3" class="large-checkbox"> (-5分)</td>
                </tr> -->
                <tr>
                  <td>安全文明-选手入场</td>
                  <td>着装整齐、符合规范</td>
                  <td>未穿工作服扣3分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.entryDressCode" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>安全文明-进入指定区域</td>
                  <td>参赛队伍按照抽签比赛顺序利用指定电脑开展数据分析</td>
                  <td>未向现场裁判申请随意使用电脑，扣3分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.enterZoneAnalysis" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>安全文明-工作申请</td>
                  <td>未向裁判报告准备完成申请开始工作</td>
                  <td>未报告扣2分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.workApplication" class="large-checkbox"> (-2分)</td>
                </tr>
                <tr>
                  <td>安全文明-选手离场</td>
                  <td>文明飞行，有序离场</td>
                  <td>作业结束后未及时向裁判汇报，扣2分</td>
                  <td><input type="checkbox" v-model="deductionsAnalysis.orderlyDepartureAnalysis" class="large-checkbox"> (-2分)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="competitionType === 'team3'">
          <h3>精细化航线设计</h3>
            <table class="deductions-table">
              <thead>
                <tr>
                  <th>评价项目</th>
                  <th>质量要求</th>
                  <th>评分标准</th>
                  <th style="width:85px">扣分</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>准备工作-进场</td>
                  <td>按照裁判要求有序进场</td>
                  <td>参赛队伍未获得现场裁判指令私自进入指定操作区，扣10分</td>
                  <td><input type="checkbox" v-model="variflightDecision.entryPreparation" class="large-checkbox"> (-10分)</td>
                </tr>
                <tr>
                  <td>航线规划-航迹规划</td>
                  <td>3名队员每人完成1基航线规划</td>
                  <td>3名队员存在有未进行航线规划者，每一人扣10分</td>
                  <td><input type="number" v-model="variflightDecision.trackPlanning" class="input-field"  min="0" max="20"  placeholder="填写扣分，最多扣20分"><br>
                    <span style="font-size:10px;" >填写扣分，最多扣20分</span>
                  </td>
                  <!-- <td><input type="checkbox" v-model="variflightDecision.trackPlanning" class="large-checkbox"> (-10分)</td> -->
                </tr>
                <tr>
                  <td>安全文明-选手入场</td>
                  <td>着装整齐、符合规范(该环节不要求佩戴安全帽)</td>
                  <td>未穿工作服扣3分</td>
                  <td><input type="checkbox" v-model="variflightDecision.dressCode" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td >安全文明-进入指定区域</td>
                  <td >参赛队伍按照抽签利用指定电脑开展数据分析</td>
                  <td>未向现场裁判申请随意使用电脑，扣3分</td>
                  <td><input type="checkbox" v-model="variflightDecision.varenterZone" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>安全文明-工作申请</td>
                  <td>工作申请</td>
                  <td>未向裁判报告准备完成申请开始工作，扣2分</td>
                  <td><input type="checkbox" v-model="variflightDecision.workReport" class="large-checkbox"> (-2分)</td>
                </tr>
                <tr>
                  <td>安全文明-选手离场</td>
                  <td>文明飞行，有序离场</td>
                  <td>作业结束后未及时向裁判汇报，扣2分</td>
                  <td><input type="checkbox" v-model="variflightDecision.civilizedFlight" class="large-checkbox"> (-2分)</td>
                </tr>
                <tr>
                  <td>比赛用时</td>
                  <td colspan="2">
                    <!-- 比赛用时按公式计算得分 -->
                  </td>
                  <td><input v-model="variflightDecision.timeScore" class="input-field"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="competitionType === 'team4'">
            <h3>精细化巡视</h3>
            <table class="deductions-table">
              <thead>
                <tr>
                  <th>评价项目</th>
                  <th>质量要求</th>
                  <th>评分标准</th>
                  <th style="width:85px">扣分</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">航前检查</td>
                  <td>进行飞行前机体外观检查，并向裁判组汇报“已完成飞行前机体外观检查”</td>
                  <td>未开展检查并汇报扣3分</td>
                  <td><input type="checkbox" v-model="variflightPatrol.varpreFlightCheck1" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>正确进行飞行器遥控器和机体连接检查，并向裁判组汇报“已完成飞行器遥控器和机体连接检查”</td>
                  <td>未开展检查并汇报扣3分</td>
                  <td><input type="checkbox" v-model="variflightPatrol.varpreFlightCheck2" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>未检查飞行安全策略及各项自检情况，并向裁判组汇报“已完成飞行安全策略及各项自检情况”</td>
                  <td>未开展检查并汇报扣3分</td>
                  <td><input type="checkbox" v-model="variflightPatrol.varpreFlightCheck3" class="large-checkbox"> (-3分)</td>
                </tr>
                <!-- <tr>
                  <td >航线设计完整程度-数据质量</td>
                  <td>1.根据杆塔精细化自主巡检点位规划要求开展航迹规划<br>
                  2.在指定目标区域，照片确保拍摄的关键设备完整，无明显遗漏、遮挡
                  </td>
                  <td>1.未根据杆塔精细化自主巡检点位规划要求开展航迹规划，每少1处点位扣5分<br>
                    2.拍摄照片遮挡扣5分，同一部位多张照片组合后仍拍摄不全每处扣5分
                  </td>
                  <td><input type="number" v-model="variflightPatrol.routeDesign1" class="input-field"  min="0" max="40"  placeholder="填写扣分，最多扣40分"><br>
                      <span style="font-size:10px;" >填写扣分，最多扣40分</span>
                    </td>
                </tr> -->
                <!-- <tr>
                  <td>在指定目标区域，照片确保拍摄的关键设备完整，无明显遗漏、遮挡</td>
                  <td>拍摄照片遮挡扣5分，同一部位多张照片组合后仍拍摄不全每处扣5分</td>
                  <td><input type="checkbox" v-model="variflightPatrol.routeDesign2" class="large-checkbox"> (-5分)</td>
                </tr> -->
                <tr>
                  <td>安全文明-选手入场</td>
                  <td>着装整齐、符合规范</td>
                  <td>1.未穿工作服扣1分<br>
                    2.未系戴安全帽或系戴错误，扣2分
                  </td>
                  <td><input type="checkbox" v-model="variflightPatrol.athleteEntry1" class="large-checkbox"> (-1分)<br>
                    <input type="checkbox" v-model="variflightPatrol.athleteEntry2" class="large-checkbox"> (-2分)
                  </td>
                </tr>
                
                <tr>
                  <td>安全文明-进入指定区域</td>
                  <td>参赛队伍在检录区检录并完成分工抽签工作</td>
                  <td>未向现场裁判申请进入指定操作区(功能自检完成后)，扣3分</td>
                  <td><input type="checkbox" v-model="variflightPatrol.enterDesignatedArea1" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>安全文明-工作汇报</td>
                  <td>工作汇报</td>
                  <td>1.未向裁判报告准备完成申请起飞，扣1分<br>
                    2.飞行结束后未向裁判汇报工作结束，扣1分
                  </td>
                  <td><input type="checkbox" v-model="variflightPatrol.varworkReport1" class="large-checkbox"> (-1分)<br>
                    <input type="checkbox" v-model="variflightPatrol.varworkReport2" class="large-checkbox"> (-1分)
                  </td>   
                </tr>
                
                <tr>
                  <td >安全文明-选手离场</td>
                  <td>文明飞行，有序离场</td>
                  <td>1.飞行结束后未及时向裁判申请、拷贝飞行数据，扣1分<br>
                    2.工作结束后场地清理不整洁，存在遗留物等，扣1分</td>
                  <td><input type="checkbox" v-model="variflightPatrol.athleteExit1" class="large-checkbox"> (-1分)<br>
                    <input type="checkbox" v-model="variflightPatrol.athleteExit2" class="large-checkbox"> (-1分)</td>
                </tr>
              
                <tr>
                  <td >安全文明-无人机飞行安全</td>
                  <td>无人机飞行安全</td>
                  <td>1.飞行过程中操控手未在操作区操作无人机飞行，扣10分<br>
                    2.自主巡检过程中选手手动干预，扣5分/人次<br>
                    3.无人机未在起降区起飞，扣5分<br>
                    4.无人机未在起降区降落，扣5分
                  </td>
                  <td>
                    <!-- <input type="checkbox" v-model="variflightPatrol.varuavSafety1" class="large-checkbox"> (-10分)<br>
                    <input type="checkbox" v-model="variflightPatrol.varuavSafety2" class="large-checkbox"> (-5分)<br>
                    <input type="checkbox" v-model="variflightPatrol.varuavSafety3" class="large-checkbox"> (-5分)<br>
                    <input type="checkbox" v-model="variflightPatrol.varuavSafety4" class="large-checkbox"> (-5分) -->
                    <input type="number" v-model="variflightPatrol.varuavSafety1" class="input-field"  min="0" max="10"  placeholder="填写扣分，最多扣10分"><br>
                      <span style="font-size:10px;" >填写扣分，最多扣10分</span>
                  </td>
                </tr>
                
              
                <tr>
                  <td>比赛用时</td>
                  <td colspan="2">
                    <!-- 比赛用时按公式计算得分 -->
                  </td>
                  <td><input  v-model="variflightPatrol.timeScore" class="input-field"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="competitionType === 'team5'">
            <h3>精细化巡视结果</h3>
            <table class="deductions-table">
              <thead>
                <tr>
                  <th>评价项目</th>
                  <th>质量要求</th>
                  <th>评分标准</th>
                  <th style="width:85px">扣分</th>
                </tr>
              </thead>
              <tbody>
               
                <tr>
                  <td >航线设计完整程度-数据质量</td>
                  <td>1.根据杆塔精细化自主巡检点位规划要求开展航迹规划<br>
                  2.在指定目标区域，照片确保拍摄的关键设备完整，无明显遗漏、遮挡
                  </td>
                  <td>1.未根据杆塔精细化自主巡检点位规划要求开展航迹规划，每少1处点位扣5分<br>
                    2.拍摄照片遮挡扣5分，同一部位多张照片组合后仍拍摄不全每处扣5分<br>
                    3.未按照标准顺序拍摄扣10分
                  </td>
                  <td><input type="number" v-model="variflightPatrol2.routeDesign1" class="input-field"  min="0" max="40"  placeholder="填写扣分，最多扣40分"><br>
                      <span style="font-size:10px;" >填写扣分，最多扣40分</span>
                    </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        <!-- <div v-if="competitionType === 'team'">
          <h3>缺陷识别分析</h3>
            <table class="deductions-table">
              <thead>
                <tr>
                  <th>评价项目</th>
                  <th>质量要求</th>
                  <th>评分标准</th>
                  <th style="width:85px">扣分</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >缺陷定级-缺陷标识</td>
                  <td>1.每组抽取20张可见光缺陷照片，缺陷命名正确，包括线、相、部件位置、缺陷名称<br>
                  2.缺陷照片命名格式要正确，命名规则为“电压等级+线路名称+杆塔号”-(中横线)“部件位置”-(中横线)“缺陷简述”-(中横线)“缺陷等级”
                  </td>
                  <td>1.漏判、误判一处缺陷，扣3分<br>
                  2.命名不正确，每张扣2分
                  </td>
                  <td><input type="number" v-model="defectAnalysis.falseNegative" class="input-field"  min="0" max="30"  placeholder="填写扣分，最多扣30分"><br>
                    <span style="font-size:10px;" >填写扣分，最多扣30分</span>
                  </td>
                 
                </tr>
                
                <tr>
                  <td>比赛用时</td>
                  <td colspan="2">
                  
                  </td>
                  <td><input  v-model="defectAnalysis.timeScore" class="input-field"></td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <div v-if="competitionType === 'individual'">
            <h3>复杂工况下无人机作业</h3>
            <table class="deductions-table">
              <thead>
                <tr>
                  <th>评价项目</th>
                  <th>质量要求</th>
                  <th>评分标准</th>
                  <th style="width:85px">扣分</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">航前检查</td>
                  <td>进行飞行前机体外观检查，并向裁判组汇报“已完成飞行前机体外观检查”</td>
                  <td>未开展检查并汇报扣3分</td>
                  <td><input type="checkbox" v-model="deductions.depreFlightCheck1" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>正确进行飞行器遥控器和机体连接检查，并向裁判组汇报“已完成飞行器遥控器和机体连接检查”</td>
                  <td>未开展检查并汇报扣3分</td>
                  <td><input type="checkbox" v-model="deductions.depreFlightCheck2" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>未检查飞行安全策略及各项自检情况，并向裁判组汇报“已完成飞行安全策略及各项自检情况”</td>
                  <td>未开展检查并汇报扣3分</td>
                  <td><input type="checkbox" v-model="deductions.depreFlightCheck3" class="large-checkbox"> (-3分)</td>
                </tr>
                <tr>
                  <td>飞行操作-安全飞行</td>
                  <td>起飞、降落精确性，飞行灵敏性</td>
                  <td>
                    1.飞行过程中碰门洞(含角桩、绳索)，每次扣3分<br>
                    2.无人机碰撞坠机，原地起飞计时不停，每次扣10分，1分钟内未完成起飞终止比赛<br>
                    3.接触面降落在中心区不扣分，区域外(含压线)扣5分<br>
                    4.飞行过程中操控手未在操作区操作无人机飞行，扣10分<br>
                    5.无人机未在起降区起飞，扣5分<br>
                    6.无人机未在起降区降落，扣5分
                  </td>
                  <td><input type="number" v-model="deductions.flySafety1" class="input-field"  min="0" max="45"  placeholder="填写扣分，最多扣45分"><br>
                    <span style="font-size:10px;" >填写扣分，最多扣45分</span>
                  </td>
                  <!-- <td>
                    <input type="checkbox" v-model="deductions.flySafety1" class="large-checkbox"> (-3分)<br>
                    <input type="checkbox" v-model="deductions.flySafety2" class="large-checkbox"> (-10分)<br>
                    <input type="checkbox" v-model="deductions.flySafety3" class="large-checkbox"> (-5分)<br>
                    <input type="checkbox" v-model="deductions.flySafety4" class="large-checkbox"> (-10分)<br>
                    <input type="checkbox" v-model="deductions.flySafety5" class="large-checkbox"> (-5分)<br>
                    <input type="checkbox" v-model="deductions.flySafety6" class="large-checkbox"> (-5分)
                  </td> -->
                </tr>
                <tr>
                  <td>照片清晰度-多角度拍照</td>
                  <td>二维码拍照完整、清晰</td>
                  <td>
                    1.二维码未能成功识别，每个扣10分<br>
                    2.漏拍每个扣10分<br>
                    3.12张拍摄二维码不全、超过4张无法识别不得时间分。
                  </td>
                  <td><input type="number" v-model="deductions.photoQuality1" class="input-field"  min="0" max="30"  placeholder="填写扣分，最多扣30分"><br>
                    <span style="font-size:10px;" >填写扣分，最多扣30分</span>
                  </td>
                  <!-- <td>
                    
                    <input type="checkbox" v-model="deductions.photoQuality1" class="large-checkbox"> (-10分)<br>
                    <input type="checkbox" v-model="deductions.photoQuality2" class="large-checkbox"> (-10分)
                  </td> -->
                </tr>
               
                <tr>
                  <td>安全文明-选手入场</td>
                  <td>着装整齐、符合规范</td>
                  <td>
                    1.未穿工作服扣1分<br>
                    2.未系戴安全帽或系戴错误，扣2分
                  </td>
                  <td>
                    <input type="checkbox" v-model="deductions.safetyCivilization1" class="large-checkbox"> (-1分)<br>
                    <input type="checkbox" v-model="deductions.safetyCivilization2" class="large-checkbox"> (-2分)
                  </td>
                </tr>
                <tr>
                  <td>安全文明-进入指定区域</td>
                  <td>参赛队伍在检录区检录并完成分工抽签工作，获得现场裁判指令后进入指定飞行操作区</td>
                  <td>
                    未向现场裁判申请进入指定操作区(功能自检完成后)，扣3分
                  </td>
                  <td>
                    <input type="checkbox" v-model="deductions.safetyCivilization3" class="large-checkbox"> (-3分)
                  </td>
                </tr>
                <tr>
                  <td>安全文明-工作汇报</td>
                  <td>工作汇报</td>
                  <td>
                    1.未向裁判报告准备完成申请起飞，扣1分<br>
                    2.飞行结束后未向裁判汇报工作结束，扣1分
                  </td>
                  <td>
                    <input type="checkbox" v-model="deductions.safetyCivilization4" class="large-checkbox"> (-1分)<br>
                    <input type="checkbox" v-model="deductions.safetyCivilization5" class="large-checkbox"> (-1分)
                  </td>
                </tr>
                <tr>
                  <td>安全文明-选手离场</td>
                  <td>文明飞行，有序离场</td>
                  <td>
                    1.飞行结束后未及时向裁判申请、拷贝飞行数据，扣1分<br>
                    2.工作结束后场地清理不整洁，存在遗留物等，扣1分
                  </td>
                  <td>
                    <input type="checkbox" v-model="deductions.deorderlyDeparture1" class="large-checkbox"> (-1分)<br>
                    <input type="checkbox" v-model="deductions.deorderlyDeparture2" class="large-checkbox"> (-1分)
                  </td>
                </tr>
                <tr>
                  <td>比赛用时</td>
                  <td colspan="2">
                    <!-- 比赛用时按公式计算得分 -->
                  </td>
                  <td><input  v-model="deductions.timeScore" class="input-field"></td>
                </tr>
              </tbody>
            </table>
          </div>
        <button type="submit" class="btn add-btn">添加</button>
      </div>
    </form>

    <div class="scores-container">
      <div class="team-scores">
        <h2>团队赛项目1</h2>
        <table class="scores-table">
          <thead>
            <tr>
              <th>编号</th>
              <!-- <th>单位</th>
              <th>理论成绩</th> -->
              <th>激光点云数据采集得分</th>
              <th>用时</th>
              <!-- <th>激光点云数据分析得分</th>
              <th>精细化航线设计得分</th>
              <th>用时</th>
              <th>精细化巡视得分</th>
              <th>用时</th>
              <th>精细化巡视结果得分</th> -->
              <!-- <th>缺陷识别分析得分</th>
              <th>用时</th> -->
              <!-- <th>成绩</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(teamScore, index) in teamScores1" :key="index">
              <td>{{ teamScore.code }}</td>
              <!-- <td>{{ teamScore.name }}</td>
              <td>{{ teamScore.theoryScore }}</td> -->
              <td>{{ teamScore.deductionsCollectionTotalScore }}</td>
              <td>{{ teamScore.deductionsCollectionTime }}</td>
              <!-- <td>{{ teamScore.deductionsAnalysisTotalScore }}</td>
              <td>{{ teamScore.variflightDecisionTotalScore }}</td>
              <td>{{ teamScore.variflightDecisionTime }}</td>
              <td>{{ teamScore.variflightPatrolTotalScore }}</td>
              <td>{{ teamScore.variflightPatrolTime }}</td>
              <td>{{ teamScore.variflightPatrol2TotalScore }}</td> -->
              <!-- <td>{{ teamScore.defectAnalysisTotalScore }}</td>
              <td>{{ teamScore.defectAnalysisTime }}</td> -->
              <!-- <td>{{ teamScore.totalScore }}</td> -->
              <td><button @click="removeScore(index, 'team1')" class="btn delete-btn">删除</button>
                <button @click="toggleExpand1(index)" class="btn expand-btn">{{ teamScore.expanded ? '收起' : '展开' }}</button>
              </td>
            </tr>
            <tr v-for="(teamScore, index) in teamScores1" :key="'log-' + index">
              <td colspan="9" v-if="teamScore.expanded">
                <div class="log-details">
                  <h3>计算日志</h3>
                  <p>{{ teamScore.log }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="team-scores">
        <h2>团队赛项目2</h2>
        <table class="scores-table">
          <thead>
            <tr>
              <th>编号</th>
              <!-- <th>单位</th>
              <th>理论成绩</th> -->
              <!-- <th>激光点云数据采集得分</th>
              <th>用时</th> -->
             <th>激光点云据分析得分</th>
               <!-- <th>精细化航线设计得分</th>
              <th>用时</th>
              <th>精细化巡视得分</th>
              <th>用时</th>
              <th>精细化巡视结果得分</th> -->
              <!-- <th>缺陷识别分析得分</th>
              <th>用时</th> -->
              <!-- <th>成绩</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(teamScore, index) in teamScores2" :key="index">
              <td>{{ teamScore.code }}</td>
              <!-- <td>{{ teamScore.name }}</td>
              <td>{{ teamScore.theoryScore }}</td> -->
              <!-- <td>{{ teamScore.deductionsCollectionTotalScore }}</td>
              <td>{{ teamScore.deductionsCollectionTime }}</td> -->
              <td>{{ teamScore.deductionsAnalysisTotalScore }}</td>
              <!-- <td>{{ teamScore.variflightDecisionTotalScore }}</td>
              <td>{{ teamScore.variflightDecisionTime }}</td>
              <td>{{ teamScore.variflightPatrolTotalScore }}</td>
              <td>{{ teamScore.variflightPatrolTime }}</td>
              <td>{{ teamScore.variflightPatrol2TotalScore }}</td> -->
              <!-- <td>{{ teamScore.defectAnalysisTotalScore }}</td>
              <td>{{ teamScore.defectAnalysisTime }}</td> -->
              <!-- <td>{{ teamScore.totalScore }}</td> -->
              <td><button @click="removeScore(index, 'team2')" class="btn delete-btn">删除</button>
                <button @click="toggleExpand2(index)" class="btn expand-btn">{{ teamScore.expanded ? '收起' : '展开' }}</button>
              </td>
            </tr>
            <tr v-for="(teamScore, index) in teamScores2" :key="'log-' + index">
              <td colspan="9" v-if="teamScore.expanded">
                <div class="log-details">
                  <h3>计算日志</h3>
                  <p>{{ teamScore.log }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="team-scores">
        <h2>团队赛项目3</h2>
        <table class="scores-table">
          <thead>
            <tr>
              <th>编号</th>
              <!-- <th>单位</th>
              <th>理论成绩</th> -->
              <!-- <th>激光点云数据采集得分</th>
              <th>用时</th> -->
             <!-- <th>激光点云据分析得分</th> -->
                <th>精细化航线设计得分</th>
              <th>用时</th>
             <!-- <th>精细化巡视得分</th>
              <th>用时</th>
              <th>精细化巡视结果得分</th> -->
              <!-- <th>缺陷识别分析得分</th>
              <th>用时</th> -->
              <!-- <th>成绩</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(teamScore, index) in teamScores3" :key="index">
              <td>{{ teamScore.code }}</td>
              <!-- <td>{{ teamScore.name }}</td>
              <td>{{ teamScore.theoryScore }}</td> -->
              <!-- <td>{{ teamScore.deductionsCollectionTotalScore }}</td>
              <td>{{ teamScore.deductionsCollectionTime }}</td> -->
              <!-- <td>{{ teamScore.deductionsAnalysisTotalScore }}</td> -->
              <td>{{ teamScore.variflightDecisionTotalScore }}</td>
              <td>{{ teamScore.variflightDecisionTime }}</td>
               <!--<td>{{ teamScore.variflightPatrolTotalScore }}</td>
              <td>{{ teamScore.variflightPatrolTime }}</td>
              <td>{{ teamScore.variflightPatrol2TotalScore }}</td> -->
              <!-- <td>{{ teamScore.defectAnalysisTotalScore }}</td>
              <td>{{ teamScore.defectAnalysisTime }}</td> -->
              <!-- <td>{{ teamScore.totalScore }}</td> -->
              <td><button @click="removeScore(index, 'team3')" class="btn delete-btn">删除</button>
                <button @click="toggleExpand3(index)" class="btn expand-btn">{{ teamScore.expanded ? '收起' : '展开' }}</button>
              </td>
            </tr>
            <tr v-for="(teamScore, index) in teamScores3" :key="'log-' + index">
              <td colspan="9" v-if="teamScore.expanded">
                <div class="log-details">
                  <h3>计算日志</h3>
                  <p>{{ teamScore.log }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="team-scores">
        <h2>团队赛项目4</h2>
        <table class="scores-table">
          <thead>
            <tr>
              <th>编号</th>
              <!-- <th>单位</th>
              <th>理论成绩</th> -->
              <!-- <th>激光点云数据采集得分</th>
              <th>用时</th> -->
             <!-- <th>激光点云据分析得分</th> -->
                <!-- <th>精细化航线设计得分</th>
              <th>用时</th> -->
            <th>精细化巡视得分</th>
              <th>用时</th>
              <!--  <th>精细化巡视结果得分</th> -->
              <!-- <th>缺陷识别分析得分</th>
              <th>用时</th> -->
              <!-- <th>成绩</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(teamScore, index) in teamScores4" :key="index">
              <td>{{ teamScore.code }}</td>
              <!-- <td>{{ teamScore.name }}</td>
              <td>{{ teamScore.theoryScore }}</td> -->
              <!-- <td>{{ teamScore.deductionsCollectionTotalScore }}</td>
              <td>{{ teamScore.deductionsCollectionTime }}</td> -->
              <!-- <td>{{ teamScore.deductionsAnalysisTotalScore }}</td> -->
              <!-- <td>{{ teamScore.variflightDecisionTotalScore }}</td>
              <td>{{ teamScore.variflightDecisionTime }}</td> -->
               <td>{{ teamScore.variflightPatrolTotalScore }}</td>
              <td>{{ teamScore.variflightPatrolTime }}</td>
              <!-- <td>{{ teamScore.variflightPatrol2TotalScore }}</td> -->
              <!-- <td>{{ teamScore.defectAnalysisTotalScore }}</td>
              <td>{{ teamScore.defectAnalysisTime }}</td> -->
              <!-- <td>{{ teamScore.totalScore }}</td> -->
              <td><button @click="removeScore(index, 'team4')" class="btn delete-btn">删除</button>
                <button @click="toggleExpand4(index)" class="btn expand-btn">{{ teamScore.expanded ? '收起' : '展开' }}</button>
              </td>
            </tr>
            <tr v-for="(teamScore, index) in teamScores4" :key="'log-' + index">
              <td colspan="9" v-if="teamScore.expanded">
                <div class="log-details">
                  <h3>计算日志</h3>
                  <p>{{ teamScore.log }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="team-scores">
        <h2>团队赛项目5</h2>
        <table class="scores-table">
          <thead>
            <tr>
              <th>编号</th>
              <!-- <th>单位</th>
              <th>理论成绩</th> -->
              <!-- <th>激光点云数据采集得分</th>
              <th>用时</th> -->
             <!-- <th>激光点云据分析得分</th> -->
                <!-- <th>精细化航线设计得分</th>
              <th>用时</th> -->
            <!-- <th>精细化巡视得分</th>
              <th>用时</th> -->
              <th>精细化巡视结果得分</th>
              <!-- <th>缺陷识别分析得分</th>
              <th>用时</th> -->
              <!-- <th>成绩</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(teamScore, index) in teamScores5" :key="index">
              <td>{{ teamScore.code }}</td>
              <!-- <td>{{ teamScore.name }}</td>
              <td>{{ teamScore.theoryScore }}</td> -->
              <!-- <td>{{ teamScore.deductionsCollectionTotalScore }}</td>
              <td>{{ teamScore.deductionsCollectionTime }}</td> -->
              <!-- <td>{{ teamScore.deductionsAnalysisTotalScore }}</td> -->
              <!-- <td>{{ teamScore.variflightDecisionTotalScore }}</td>
              <td>{{ teamScore.variflightDecisionTime }}</td> -->
               <!-- <td>{{ teamScore.variflightPatrolTotalScore }}</td>
              <td>{{ teamScore.variflightPatrolTime }}</td> -->
              <td>{{ teamScore.variflightPatrol2TotalScore }}</td>
              <!-- <td>{{ teamScore.defectAnalysisTotalScore }}</td>
              <td>{{ teamScore.defectAnalysisTime }}</td> -->
              <!-- <td>{{ teamScore.totalScore }}</td> -->
              <td><button @click="removeScore(index, 'team5')" class="btn delete-btn">删除</button>
                <button @click="toggleExpand5(index)" class="btn expand-btn">{{ teamScore.expanded ? '收起' : '展开' }}</button>
              </td>
            </tr>
            <tr v-for="(teamScore, index) in teamScores5" :key="'log-' + index">
              <td colspan="9" v-if="teamScore.expanded">
                <div class="log-details">
                  <h3>计算日志</h3>
                  <p>{{ teamScore.log }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="individual-scores">
        <h2>个人赛项目</h2>
        <table class="scores-table">
          <thead>
            <tr>
              <th>编号</th>
              <!-- <th>运动员名称</th>
              <th>理论成绩</th> -->
              <th>复杂工况下无人机作业得分</th>
              <th>用时</th>
              <!-- <th>成绩</th> -->
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(score, index) in individualScores" :key="index">
              <td>{{ score.code }}</td>
              <!-- <td>{{ score.athleteName }}</td>
              <td>{{ score.theoryScore }}</td> -->
              <td>{{ score.deductionsTotalScore }}</td>
              <td>{{ score.deductionsTime }}</td>
              <!-- <td>{{ score.totalScore }}</td> -->
              <td><button @click="removeScore(index, 'individual')" class="btn delete-btn">删除</button>
                <button @click="toggleExpand6(index)" class="btn expand-btn">{{ individualScores.expanded ? '收起' : '展开' }}</button></td>
            </tr>
            <tr v-for="(teamScore, index) in individualScores" :key="'log-' + index">
              <td colspan="9" v-if="teamScore.expanded">
                <div class="log-details">
                  <h3>计算日志</h3>
                  <p>{{ teamScore.log }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
    <button @click="exportToExcel()">导出团队赛成绩</button>
    <!-- <button @click="goToRankings" class="btn">查看排名结果</button> -->
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
  name: 'InputScores',
  computed: {
   
  },
  data() {
    return {
      competitionType: '',
      // name: '',
      code: '', 
      // athleteName: '',
      // theoryScore: null,
      deductionsCollection: {
        preFlightCheck1: false,
        preFlightCheck2: false,
        preFlightCheck3: false,
        dressCode1: false,
        dressCode2: false,
        enterZone: false,
        workReport1: false,
        workReport2: false,
        orderlyDeparture1: false,
        orderlyDeparture2: false,
        uavSafety1: false,
        uavSafety2: false,
        uavSafety3: false,
        timeScore:''
      },
      deductionsAnalysis: {
        dataProcessing: false,
        cloudDataQuality1: false,
        // cloudDataQuality2: false,
        cloudDataQuality3: false,
        // dataAnalysis1: 0,
        entryDressCode: false,
        enterZoneAnalysis: false,
        workApplication: false,
        orderlyDepartureAnalysis: false,
        timeScore:''
      },
      // 新增的精细化航线设计评分项目变量初始化
    variflightDecision: {
      entryPreparation: false,
      trackPlanning: 0,
      dressCode: false,
      varenterZone: false,
      workReport: false,
      civilizedFlight: false,
      timeScore:''
    },

    // 新增的精细化巡视评分项目变量初始化
    variflightPatrol: {
      varpreFlightCheck1: false,
      varpreFlightCheck2: false,
      varpreFlightCheck3: false,
      // routeDesign1: 0,
      athleteEntry1: false,
      athleteEntry2: false,
      enterDesignatedArea1: false,
      varworkReport1: false,
      varworkReport2: false,
      athleteExit1: false,
      athleteExit2: false,
      varuavSafety1: 0,
      // varuavSafety2: false,
      // varuavSafety3: false,
      // varuavSafety4: false,
      timeScore: ''
    },
    variflightPatrol2: {
      routeDesign1: 0,
    },
    // defectAnalysis:{
    //   falseNegative:0,
    //   timeScore: ''
    // },
    deductions: {
      depreFlightCheck1: false,
      depreFlightCheck2: false,
      depreFlightCheck3: false,
      flySafety1: 0,
      // flySafety1: false,
      // flySafety2: false,
      // flySafety3: false,
      // flySafety4: false,
      // flySafety5: false,
      // flySafety6: false,
      photoQuality1: 0,
      safetyCivilization1: false,
      safetyCivilization2: false,
      safetyCivilization3: false,
      safetyCivilization4: false,
      safetyCivilization5: false,
      deorderlyDeparture1: false,
      deorderlyDeparture2: false,
      timeScore: ''
    },
      teamScores1: [],
      teamScores2: [],
      teamScores3: [],
      teamScores4: [],
      teamScores5: [],
      individualScores: [],
      logs: []
    };
  },
  methods: {
    exportToExcel() {
      const teamHeaders1 = ['编号',  '激光点云数据采集得分', '用时'];
      const teamHeaders2 = ['编号',  '激光点云数据分析得分'];
      const teamHeaders3 = ['编号',  '精细化航线设计得分', '用时'];
      const teamHeaders4 = ['编号',  '精细化巡视得分', '用时'];
      const teamHeaders5 = ['编号',  '精细化巡视结果得分'];
      const individualHeaders = ['编号', '复杂工况下无人机作业得分', '用时'];

      const teamData1 = this.teamScores1.map(score => [
        score.code,
        score.deductionsCollectionTotalScore,
        score.deductionsCollectionTime
      ]);
      const teamData2 = this.teamScores2.map(score => [
        score.code,
        score.deductionsAnalysisTotalScore
      ]);
      const teamData3 = this.teamScores3.map(score => [
        score.code,
        score.variflightDecisionTotalScore,
        score.variflightDecisionTime
      ]);
      const teamData4 = this.teamScores4.map(score => [
        score.code,
        score.variflightPatrolTotalScore,
        score.variflightPatrolTime
      ]);
      const teamData5 = this.teamScores5.map(score => [
        score.code,
        score.variflightPatrol2TotalScore
      ]);
      const individualData = this.individualScores.map(score => [
        score.code,
        score.deductionsTotalScore,
        score.deductionsTime
      ]);

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Append team scores sheet
      const teamSheet1 = XLSX.utils.aoa_to_sheet([teamHeaders1, ...teamData1]);
      XLSX.utils.book_append_sheet(wb, teamSheet1, '团队项目1');
      const teamSheet2 = XLSX.utils.aoa_to_sheet([teamHeaders2, ...teamData2]);
      XLSX.utils.book_append_sheet(wb, teamSheet2, '团队项目2');
      const teamSheet3 = XLSX.utils.aoa_to_sheet([teamHeaders3, ...teamData3]);
      XLSX.utils.book_append_sheet(wb, teamSheet3, '团队项目3');
      const teamSheet4 = XLSX.utils.aoa_to_sheet([teamHeaders4, ...teamData4]);
      XLSX.utils.book_append_sheet(wb, teamSheet4, '团队项目4');
      const teamSheet5 = XLSX.utils.aoa_to_sheet([teamHeaders5, ...teamData5]);
      XLSX.utils.book_append_sheet(wb, teamSheet5, '团队项目5');

      // Append individual scores sheet
      const individualSheet = XLSX.utils.aoa_to_sheet([individualHeaders, ...individualData]);
      XLSX.utils.book_append_sheet(wb, individualSheet, '个人项目');

      // Generate and download the Excel file
      XLSX.writeFile(wb, '成绩.xlsx');
    },
    // goToRankings() {
    //   this.$router.push({ name: 'ScoreRankings',params: { teamScores: this.teamScores,individualScores:this.individualScores }});
    // },
   
    // toggleLog(index) {
    //   this.teamScores1[index].expanded = !this.teamScores1[index].expanded;
    //   this.teamScores2[index].expanded = !this.teamScores2[index].expanded;
    //   this.teamScores3[index].expanded = !this.teamScores3[index].expanded;
    //   this.teamScores4[index].expanded = !this.teamScores4[index].expanded;
    //   this.teamScores5[index].expanded = !this.teamScores5[index].expanded;
    // },
    validateTimeSettings() {
     
    },
    addScore() {
      // 假设有一个输入对象包含所有需要的时间分
      if (!this.code) {
        alert("请输入编号!");
        return;
      }
      // if (this.competitionType === 'team' && !this.name) {
      //   alert("请输入单位名称!");
      //   return;
      // }
      // if (this.competitionType === 'individual' && !this.athleteName) {
      //   alert("请输入运动员名称!");
      //   return;
      // }
      // if (!this.theoryScore) {
      //   alert("请输入理论成绩!");
      //   return;
      // }
     
    var log='';
    const newScores = {
      // name: this.name,
      // athleteName:this.athleteName,
      // theoryScore: this.theoryScore,
      deductionsCollectionTotalScore: 0,
      deductionsCollectionTime:'',
      deductionsAnalysisTotalScore: 0,
      
      variflightDecisionTotalScore: 0,
      variflightDecisionTime:'',
      variflightPatrolTotalScore: 0,
      variflightPatrolTime:'',
      variflightPatrol2TotalScore:0,
      // defectAnalysisTotalScore: 0,
      // defectAnalysisTime:'',
      deductionsTotalScore: 0,
      deductionsTime:'',

      totalScore: 0,
      expanded: false,
      log: ''
    };
    const summaries = [];
     if(this.competitionType == 'team1'){
        log="【激光点云数据采集项目："
        newScores.deductionsCollectionTotalScore = this.calculateTimeScores( 'deductionsCollection')-this.calculateDeductions(this.deductionsCollection);
        log+=this.getlog(this.deductionsCollection.timeScore, 'deductionsCollection');
        log+=",合计扣分："+this.calculateDeductions(this.deductionsCollection)+"分;】\n";
        summaries.push(log);
        newScores.deductionsCollectionTime=this.deductionsCollection.timeScore;
       
        newScores.totalScore = 
        parseFloat(newScores.deductionsCollectionTotalScore);
     }else if(this.competitionType == 'team2'){
        log="【激光点云数据分析项目："
        newScores.deductionsAnalysisTotalScore = this.calculateTimeScores( 'deductionsAnalysis')-this.calculateDeductions(this.deductionsAnalysis);
        log+=this.getlog(this.deductionsAnalysis.timeScore, 'deductionsAnalysis');
        log+=",合计扣分："+this.calculateDeductions(this.deductionsAnalysis)+"分;】\n";
        summaries.push(log);
        // newScores.deductionsCollectionTime=this.deductionsAnalysis.timeScore;
        newScores.totalScore = 
        parseFloat(newScores.deductionsAnalysisTotalScore);
      }else if(this.competitionType == 'team3'){
        log="【精细化航线设计："
        newScores.variflightDecisionTotalScore = this.calculateTimeScores( 'variflightDecision')-this.calculateDeductions(this.variflightDecision);
        log+=this.getlog(this.variflightDecision.timeScore, 'variflightDecision');
        log+=",合计扣分："+this.calculateDeductions(this.variflightDecision)+"分;】\n";
        summaries.push(log);
        newScores.variflightDecisionTime=this.variflightDecision.timeScore;
        newScores.totalScore = 
        parseFloat(newScores.variflightDecisionTotalScore);
      }else if(this.competitionType == 'team4'){

        log="【精细化巡视："
        newScores.variflightPatrolTotalScore = this.calculateTimeScores( 'variflightPatrol')-this.calculateDeductions(this.variflightPatrol);
        log+=this.getlog(this.variflightPatrol.timeScore, 'variflightPatrol');
        log+=",合计扣分："+this.calculateDeductions(this.variflightPatrol)+"分;】\n";
        summaries.push(log);
        newScores.variflightPatrolTime=this.variflightPatrol.timeScore;
        newScores.totalScore = 
        parseFloat(newScores.variflightPatrolTotalScore);
        
      }else if(this.competitionType == 'team5'){
        log="【精细化巡视结果："
        newScores.variflightPatrol2TotalScore = this.calculateTimeScores( 'variflightPatrol2')-this.calculateDeductions(this.variflightPatrol2);
        log+=this.getlog(this.variflightPatrol2.timeScore, 'variflightPatrol2');
        log+=",合计扣分："+this.calculateDeductions(this.variflightPatrol2)+"分;】\n";
        summaries.push(log);
        newScores.totalScore = 
        parseFloat(newScores.variflightPatrol2TotalScore);
        // newScores.variflightPatrolTime=this.variflightPatrol.timeScore;


        // log="【缺陷识别分析项目："
        // newScores.defectAnalysisTotalScore = this.calculateTimeScores( 'defectAnalysis')-this.calculateDeductions(this.defectAnalysis);
        // log+=this.getlog(this.defectAnalysis.timeScore, 'defectAnalysis');
        // log+=",合计扣分："+this.calculateDeductions(this.defectAnalysis)+"分;】\n";
        // summaries.push(log);
        // newScores.defectAnalysisTime=this.defectAnalysis.timeScore;

        // newScores.totalScore = 
        // // parseFloat(newScores.theoryScore) +
        // parseFloat(newScores.deductionsCollectionTotalScore) +
        // parseFloat(newScores.deductionsAnalysisTotalScore)+
        // parseFloat(newScores.variflightDecisionTotalScore) +
        // parseFloat(newScores.variflightPatrolTotalScore) +
        // parseFloat(newScores.variflightPatrol2TotalScore) 
        // +
        // parseFloat(newScores.defectAnalysisTotalScore);
      }else{
        log="【复杂工况下无人机作业项目："
        newScores.deductionsTotalScore = this.calculateTimeScores( 'deductions')-this.calculateDeductions(this.deductions);
        log+=this.getlog(this.deductions.timeScore, 'deductions');
        log+=",合计扣分："+this.calculateDeductions(this.deductions)+"分;】\n";
        summaries.push(log);
        newScores.deductionsTime=this.deductions.timeScore;

        newScores.totalScore = 
        // parseFloat(newScores.theoryScore) +
        parseFloat(newScores.deductionsTotalScore);
      }

      newScores.code=this.code;
      newScores.totalScore=newScores.totalScore.toFixed(1);
      newScores.log=summaries.join('\n');
      if (this.competitionType === 'team1') {
        this.teamScores1.push(newScores);
      } else if (this.competitionType === 'team2') {
        this.teamScores2.push(newScores);
      } else if (this.competitionType === 'team3') {
        this.teamScores3.push(newScores);
      } else if (this.competitionType === 'team4') {
        this.teamScores4.push(newScores);
      } else if (this.competitionType === 'team5') {
        this.teamScores5.push(newScores);
      } else  if (this.competitionType === 'individual') {
        this.individualScores.push(newScores);
      }

      this.saveScores();
      this.resetForm();
    },
    resetForm() {
        this.competitionType = '';
        this.code = '';
        // this.name = '';
        // this.athleteName = '';
        // this.theoryScore = null;
        this.timeScore = null;
       
        this.deductionsCollection = {
          preFlightCheck1: false,
          preFlightCheck2: false,
          preFlightCheck3: false,
          dressCode1: false,
          dressCode2: false,
          enterZone: false,
          workReport1: false,
          workReport2: false,
          orderlyDeparture1: false,
          orderlyDeparture2: false,
          uavSafety1: false,
          uavSafety2: false,
          uavSafety3: false,
        };
        this.deductionsAnalysis = {
          dataProcessing: false,
          cloudDataQuality1: false,
          // cloudDataQuality2: false,
          cloudDataQuality3: false,
          // dataAnalysis1: 0,
          entryDressCode: false,
          enterZoneAnalysis: false,
          workApplication: false,
          orderlyDepartureAnalysis: false,
        };
        // 重置精细化航线设计评分项目变量
        this.variflightDecision = {
          entryPreparation: false,
          trackPlanning: 0,
          dressCode: false,
          varenterZone: false,
          workReport: false,
          civilizedFlight: false,
          timeScore:0
        };

        // 重置精细化巡视评分项目变量
        this.variflightPatrol = {
          preFlightCheck1: false,
          preFlightCheck2: false,
          preFlightCheck3: false,
          // routeDesign1: 0,
          athleteEntry1: false,
          athleteEntry2: false,
          enterDesignatedArea1: false,
          varworkReport1: false,
          varworkReport2: false,
          athleteExit1: false,
          athleteExit2: false,
          varuavSafety1: 0,
          // varuavSafety2: false,
          // varuavSafety3: false,
          // varuavSafety4: false,
          timeScore: 0
        };
        this.variflightPatrol2 = {
          routeDesign1: 0,
        },
        
        // 重置缺陷识别分析评分项目变量
        // this.defectAnalysis = {
        //   falseNegative:0,
        //   timeScore: 0
        // };
        this.deductions = {
          depreFlightCheck1: false,
          depreFlightCheck2: false,
          depreFlightCheck3: false,
          flySafety1: 0,
          // flySafety1: false,
          // flySafety2: false,
          // flySafety3: false,
          // flySafety4: false,
          // flySafety5: false,
          // flySafety6: false,
          photoQuality1: 0,
          safetyCivilization1: false,
          safetyCivilization2: false,
          safetyCivilization3: false,
          safetyCivilization4: false,
          safetyCivilization5: false,
          deorderlyDeparture1: false,
          deorderlyDeparture2: false,
          timeScore: 0
        };
      },
      
      calculateCollectionDeductionSummary() {
      const deductionTexts = [];
      if (this.deductionsCollection.preFlightCheck1) deductionTexts.push('航前检查 - 未开展检查并汇报扣3分');
      if (this.deductionsCollection.preFlightCheck2) deductionTexts.push('航前检查 - 未开展检查并汇报扣3分');
      if (this.deductionsCollection.preFlightCheck3) deductionTexts.push('航前检查 - 未检查飞行安全策略及各项自检情况，扣3分');
      if (this.deductionsCollection.dressCode1) deductionTexts.push('选手入场 - 未穿工作服扣1分');
      if (this.deductionsCollection.dressCode2) deductionTexts.push('选手入场 - 未系戴安全帽或系戴错误，扣2分');
      if (this.deductionsCollection.enterZone) deductionTexts.push('进入指定区域 - 未向现场裁判申请进入指定操作区，扣3分');
      if (this.deductionsCollection.workReport1) deductionTexts.push('工作汇报 - 未向裁判报告准备完成申请起飞，扣1分');
      if (this.deductionsCollection.workReport2) deductionTexts.push('工作汇报 - 飞行结束后未向裁判汇报工作结束，扣1分');
      if (this.deductionsCollection.orderlyDeparture1) deductionTexts.push('文明飞行，有序离场 - 飞行结束后未及时向裁判申请、拷贝飞行数据，扣1分');
      if (this.deductionsCollection.orderlyDeparture2) deductionTexts.push('文明飞行，有序离场 - 工作结束后场地清理不整洁，存在遗留物等，扣1分');
      if (this.deductionsCollection.uavSafety1) deductionTexts.push('无人机飞行安全 - 飞行过程中操控手未在操作区操作无人机飞行，扣10分');
      if (this.deductionsCollection.uavSafety2) deductionTexts.push('无人机飞行安全 - 无人机未在起降区起飞，扣5分');
      if (this.deductionsCollection.uavSafety3) deductionTexts.push('无人机飞行安全 - 无人机未在起降区降落或压线、降落在起降区域外，扣5分');
      // if(this.tmin && this.tmax && this.timeScore)
      // {
      //   const timeScore = 10 + 20 * (this.tmax - this.timeScore) / (this.tmax - this.tmin);
      //   const tims=Math.max(0, timeScore.toFixed(1));
      //   deductionTexts.push('比赛用时得分 - 用时：'+this.timeScore+'(最高用时'+this.tmax+';最低用时'+this.tmin+')；得分'+tims+'分');
      // } else{
      //   deductionTexts.push('比赛用时得分 - 未录入');
      // }
      return deductionTexts.join('; ');
    },
    calculateAnalysisDeductionSummary() {
      const deductionTexts = [];
      if (this.deductionsAnalysis.dataProcessing) deductionTexts.push('数据处理 - 未获得现场裁判指令私自进入指定操作区，扣5分');
      if (this.deductionsAnalysis.cloudDataQuality1) deductionTexts.push('激光点云数据质量 - 未完整采集杆塔周边50米、边导线外侧50米范围内点云数据，扣15分');
      // if (this.deductionsAnalysis.cloudDataQuality2) deductionTexts.push('激光点云数据质量 - 每平方米点数不足或过多，扣10分');
      if (this.deductionsAnalysis.cloudDataQuality3) deductionTexts.push('激光点云数据质量 - 杆塔点云数据结构不完整，扣10分');
      // if (this.deductionsAnalysis.dataAnalysis1) deductionTexts.push('数据分析质量-点云数据分析- 该项扣分:'+this.deductionsAnalysis.dataAnalysis1+';');
      if (this.deductionsAnalysis.entryDressCode) deductionTexts.push('选手入场 - 未穿工作服扣3分');
      if (this.deductionsAnalysis.enterZoneAnalysis) deductionTexts.push('进入指定区域 - 未向现场裁判申请随意使用电脑，扣3分');
      if (this.deductionsAnalysis.workApplication) deductionTexts.push('工作申请 - 未向裁判报告准备完成申请开始工作，扣2分');
      if (this.deductionsAnalysis.orderlyDepartureAnalysis) deductionTexts.push('选手离场 - 作业结束后未及时向裁判汇报，扣2分');
      return deductionTexts.join('; ');
    },

    variflightDecisionSummary() {
      const deductionTexts = [];
      if (this.variflightDecision.entryPreparation) deductionTexts.push('准备工作-进场 - 参赛队伍未获得现场裁判指令私自进入指定操作区，扣10分');
      if (this.variflightDecision.trackPlanning) deductionTexts.push('航线规划-航迹规划 - 3名队员存在有未进行航线规划者，每一人扣10分，该项扣'+this.variflightDecision.trackPlanning+';');
      if (this.variflightDecision.dressCode) deductionTexts.push('安全文明-选手入场 - 未穿工作服扣3分');
      if (this.variflightDecision.varenterZone) deductionTexts.push('安全文明-进入指定区域 - 未向现场裁判申请随意使用电脑，扣3分');
      if (this.variflightDecision.workReport) deductionTexts.push('安全文明-工作申请 - 未向裁判报告准备完成申请开始工作，扣2分');
      if (this.variflightDecision.civilizedFlight) deductionTexts.push('安全文明-选手离场 - 作业结束后未及时向裁判汇报，扣2分');
      return deductionTexts.join('; ');
    },
    variflightPatrolSummary() {
      const deductionTexts = [];
      // 航前检查
      if (this.variflightPatrol.varpreFlightCheck1) deductionTexts.push('航前检查 - 未进行飞行前机体外观检查，扣3分');
      if (this.variflightPatrol.varpreFlightCheck2) deductionTexts.push('航前检查 - 未正确进行飞行器遥控器和机体连接检查，扣3分');
      if (this.variflightPatrol.varpreFlightCheck3) deductionTexts.push('航前检查 - 未检查飞行安全策略及各项自检情况，扣3分');
      
      // 安全文明
      if (this.variflightPatrol.athleteEntry1) deductionTexts.push('安全文明 - 未穿工作服，扣1分');
      if (this.variflightPatrol.athleteEntry2) deductionTexts.push('安全文明 - 未系戴安全帽或系戴错误，扣2分');
      if (this.variflightPatrol.enterDesignatedArea1) deductionTexts.push('安全文明 - 未向现场裁判申请进入指定操作区，扣3分');
      if (this.variflightPatrol.varworkReport1) deductionTexts.push('安全文明 - 未向裁判报告准备完成申请起飞，扣1分');
      if (this.variflightPatrol.varworkReport2) deductionTexts.push('安全文明 - 飞行结束后未向裁判汇报工作结束，扣1分');
      if (this.variflightPatrol.athleteExit1) deductionTexts.push('安全文明 - 飞行结束后未及时向裁判申请、拷贝飞行数据，扣1分');
      if (this.variflightPatrol.athleteExit2) deductionTexts.push('安全文明 - 工作结束后场地清理不整洁，存在遗留物等，扣1分');
      // 无人机飞行安全
      if (this.variflightPatrol.varuavSafety1) deductionTexts.push('无人机飞行安全，,该项扣'+this.variflightPatrol.varuavSafety1+';');
      // if (this.variflightPatrol.varuavSafety2) deductionTexts.push('无人机飞行安全 - 自主巡检过程中选手手动干预，扣5分/人次');
      // if (this.variflightPatrol.varuavSafety3) deductionTexts.push('无人机飞行安全 - 无人机未在起降区起飞，扣5分');
      // if (this.variflightPatrol.varuavSafety4) deductionTexts.push('无人机飞行安全 - 无人机未在起降区降落，扣5分');
      return deductionTexts.join('; ');
    },
    variflightPatrol2Summary() {
      const deductionTexts = [];
      if (this.variflightPatrol2.routeDesign1) deductionTexts.push('航线设计 - 1.未根据杆塔精细化自主巡检点位规划要求开展航迹规划，扣5分，2.拍摄照片遮挡扣5分、同一部位多张照片组合后仍拍摄不全每处扣5分；该项扣'+this.variflightPatrol2.routeDesign1);
      return deductionTexts.join('; ');
    },
    // defectAnalysisSummary() {
    //   const deductionTexts = [];
    //   if (this.defectAnalysis.falseNegative) deductionTexts.push('缺陷定级 - 1.漏判、误判一处缺陷，扣10分;2.命名不正确，每张扣2分;该项扣'+this.defectAnalysis.falseNegative+'分');
    //   return deductionTexts.join('; ');
    // },
    deductionsSummary() {
      const deductionTexts = [];
      if (this.deductions.depreFlightCheck1) deductionTexts.push('航前检查 - 进行飞行前机体外观检查，并向裁判组汇报“已完成飞行前机体外观检查”，扣[3]分');
      if (this.deductions.depreFlightCheck2) deductionTexts.push('航前检查 - 正确进行飞行器遥控器和机体连接检查，并向裁判组汇报“已完成飞行器遥控器和机体连接检查”，扣[3]分');
      if (this.deductions.depreFlightCheck3) deductionTexts.push('航前检查 - 未检查飞行安全策略及各项自检情况，并向裁判组汇报“已完成飞行安全策略及各项自检情况”，扣[3]分');
      if (this.deductions.flySafety1) deductionTexts.push('飞行操作 - 安全飞行 - 飞行过程中碰门洞(含角桩、绳索)，每次扣3分;无人机碰撞坠机，原地起飞计时不停，每次扣10分，1分钟内未完成起飞终止比赛;接触面降落在中心区不扣分，区域外(含压线)扣5分,飞行过程中操控手未在操作区操作无人机飞行，扣10分,无人机未在起降区起飞，扣5分,无人机未在起降区降落，扣5分,该项扣['+this.deductions.flySafety1+']分');
      // if (this.deductions.flySafety1) deductionTexts.push('飞行操作 - 安全飞行 - 飞行过程中碰门洞(含角桩、绳索)，每次扣[3]分');
      // if (this.deductions.flySafety2) deductionTexts.push('飞行操作 - 安全飞行 - 无人机碰撞坠机，原地起飞计时不停，每次扣10分，1分钟内未完成起飞终止比赛;')
      // if (this.deductions.flySafety3) deductionTexts.push('飞行操作 - 安全飞行 - 接触面降落在中心区不扣分，区域外(含压线)扣[5]分');
      // if (this.deductions.flySafety4) deductionTexts.push('飞行操作 - 安全飞行 - 飞行过程中操控手未在操作区操作无人机飞行，扣[10]分');
      // if (this.deductions.flySafety5) deductionTexts.push('飞行操作 - 安全飞行 - 无人机未在起降区起飞，扣[5]分');
      // if (this.deductions.flySafety6) deductionTexts.push('飞行操作 - 安全飞行 - 无人机未在起降区降落，扣[5]分');
      if (this.deductions.photoQuality1) deductionTexts.push('照片清晰度 - 多角度拍照 - 1.二维码未能成功识别，每个扣10分;2.漏拍每个扣10分;该项扣['+this.deductions.photoQuality1+']分');
      // if (this.deductions.photoQuality2) deductionTexts.push('照片清晰度 - 多角度拍照 - 漏拍每个扣10分');
      if (this.deductions.safetyCivilization1) deductionTexts.push('安全文明 - 选手入场 - 未穿工作服扣[1]分');
      if (this.deductions.safetyCivilization2) deductionTexts.push('安全文明 - 选手入场 - 未系戴安全帽或系戴错误，扣[2]分');
      if (this.deductions.safetyCivilization3) deductionTexts.push('安全文明 - 进入指定区域 - 未向现场裁判申请进入指定操作区(功能自检完成后)，扣[3]分');
      if (this.deductions.safetyCivilization4) deductionTexts.push('安全文明 - 工作汇报 - 未向裁判报告准备完成申请起飞，扣[1]分');
      if (this.deductions.safetyCivilization5) deductionTexts.push('安全文明 - 工作汇报 - 飞行结束后未向裁判汇报工作结束，扣[1]分');
      if (this.deductions.deorderlyDeparture1) deductionTexts.push('安全文明 - 选手离场 - 飞行结束后未及时向裁判申请、拷贝飞行数据，扣[1]分');
      if (this.deductions.deorderlyDeparture2) deductionTexts.push('安全文明 - 选手离场 - 工作结束后场地清理不整洁，存在遗留物等，扣[1]分');
      return deductionTexts.join('; ');
    },
    //计算扣减分
    calculateDeductions(de) {
      let totalDeductions = 0;

      const deductionValues = {
        preFlightCheck1: 3,
        preFlightCheck2: 3,
        preFlightCheck3: 3,
        dressCode1: 1,
        dressCode2: 2,
        enterZone: 3,
        workReport1: 1,
        workReport2: 1,
        orderlyDeparture1: 1,
        orderlyDeparture2: 1,
        uavSafety1: 10,
        uavSafety2: 5,
        uavSafety3: 5,
        dataProcessing: 5,
        cloudDataQuality1: 15,
        // cloudDataQuality2: 10,
        cloudDataQuality3: 10,
        // dataAnalysis1: parseFloat(this.deductionsAnalysis.dataAnalysis1),
        entryDressCode: 3,
        enterZoneAnalysis: 3,
        workApplication: 2,
        orderlyDepartureAnalysis: 2,
        entryPreparation: 10,
        trackPlanning: parseFloat(this.variflightDecision.trackPlanning),
        dressCode: 3,
        varenterZone: 3,
        workReport: 2,
        civilizedFlight: 2,
        varpreFlightCheck1: 3,
        varpreFlightCheck2: 3,
        varpreFlightCheck3: 3,
        routeDesign1: parseFloat(this.variflightPatrol2.routeDesign1),
        athleteEntry1: 1,
        athleteEntry2: 2,
        enterDesignatedArea1: 3,
        varworkReport1: 1,
        varworkReport2: 1,
        athleteExit1: 1,
        athleteExit2: 1,
        varuavSafety1:  parseFloat(this.variflightPatrol.varuavSafety1),
        varuavSafety2: 5,
        varuavSafety3: 5,
        varuavSafety4: 5,
        // falseNegative: parseFloat(this.defectAnalysis.falseNegative),
        // falseName: 2,
        depreFlightCheck1: 3,
        depreFlightCheck2: 3,
        depreFlightCheck3: 3,
        flySafety1:parseFloat(this.deductions.flySafety1),
        // flySafety1: 3,
        // flySafety2: 10,
        // flySafety3: 5,
        // flySafety4: 10,
        // flySafety5: 5,
        // flySafety6: 5,
        photoQuality1: parseFloat(this.deductions.photoQuality1),
        safetyCivilization1: 1,
        safetyCivilization2: 2,
        safetyCivilization3: 3,
        safetyCivilization4: 1,
        safetyCivilization5: 1,
        deorderlyDeparture1: 1,
        deorderlyDeparture2: 1,
      };

      for (const key in de) {
        if (de[key]) {
          totalDeductions += deductionValues[key] || 0;
        }
      }
      return totalDeductions;
    },
    // //计算时间分
    calculateTimeScores (de) {
      // var log='';
      let score=0;
      switch (de) {
        case 'deductionsCollection':
          score = 70 ;
          //log += `激光点云数据采集得分计算: 40 + 10 + 20 * ((max - ${timeScore}) / (max - min)) = ${score}`;
          break;
        case 'deductionsAnalysis':
          score = 45;
          break;
        case 'variflightDecision':
          score = 70 ;
          //log += `精细化航线设计得分计算: 40 + + (timeScore<=1800?10:0) + 20 + 20 * ((max - ${timeScore}) / (max - min)) = ${score}`;
          break; 
        case 'variflightPatrol':
          score = 40 ;
          //log += `精细化巡视得分计算: 70 +  (timeScore<=600?5:0) + + 5 * ((max - ${timeScore}) / (max - min)) = ${score}`;
          break;
        case 'variflightPatrol2':
         score = 40 ;
         //log += `精细化巡视得分计算: 70 +  (timeScore<=600?5:0) + + 5 * ((max - ${timeScore}) / (max - min)) = ${score}`;
         break;
        case 'defectAnalysis':
          score = 50 ;
          //log += `缺陷识别分析得分计算: 30 + (timeScore<=1200?10:0) + 10 * ((max - ${timeScore}) / (max - min)) = ${score}`;
          break;
        case 'deductions':
         
          score = 115;
          // log += `复杂工况下无人机作业得分计算: 90 + (timeScore<=240?5:0) + 15 * ((max - ${timeScore}) / (max - min)) = ${score}`;
          break;
        default:
          break;
      }
      
      
      return score.toFixed(1);
    },
    getlog(times,de){
     
      var log='';
      var timeScore = times;
        switch (de) {
          case 'deductionsCollection':
            log = `激光点云数据采集时间: ${timeScore});总分70,扣减项：`
            +this.calculateCollectionDeductionSummary();
            break;
          case 'deductionsAnalysis':
            log = `总分45,扣减项：`+this.calculateAnalysisDeductionSummary();
            break;
          case 'variflightDecision':
            log = `精细化航线设计时间: ${timeScore});总分70,扣减项：`+this.variflightDecisionSummary();
            break; 
          case 'variflightPatrol':
            log = `精细化巡视时间:  ${timeScore});总分40,扣减项：`+this.variflightPatrolSummary();
            break;
          case 'variflightPatrol2':
            log = `总分40,扣减项：`+this.variflightPatrol2Summary();
            break;
          // case 'defectAnalysis':
          //   log = `缺陷识别分析时间: ${timeScore});扣减项：`+this.defectAnalysisSummary();
          //   break;
          case 'deductions':
            log = `总分115,复杂工况下无人机作业时间:  ${timeScore});扣减项：`+this.deductionsSummary();
            break;
          default:
            break;
        }
        return log;
    },
    toggleExpand1(index) {
      this.teamScores1[index].expanded = !this.teamScores1[index].expanded;
    },
    toggleExpand2(index) {
      this.teamScores2[index].expanded = !this.teamScores2[index].expanded;
    },
    toggleExpand3(index) {
      this.teamScores3[index].expanded = !this.teamScores3[index].expanded;
    },
    toggleExpand4(index) {
      this.teamScores4[index].expanded = !this.teamScores4[index].expanded;
    },
    toggleExpand5(index) {
      this.teamScores5[index].expanded = !this.teamScores5[index].expanded;
    },
    toggleExpand6(index) {
      this.individualScores[index].expanded = !this.individualScores[index].expanded;
    },
    // calculateTotalScore() {
    //   const maxPoints = 40;
    //   const pointsAfterDeductions = maxPoints - this.calculateDeductions();
    //   const timeScore = 10 + 20 * (this.tmax - this.timeScore) / (this.tmax - this.tmin);
    //   return (pointsAfterDeductions + timeScore).toFixed(1);
    // },
    saveScores() {
      localStorage.setItem('teamScores1', JSON.stringify(this.teamScores1));
      localStorage.setItem('teamScores2', JSON.stringify(this.teamScores2));
      localStorage.setItem('teamScores3', JSON.stringify(this.teamScores3));
      localStorage.setItem('teamScores4', JSON.stringify(this.teamScores4));
      localStorage.setItem('teamScores5', JSON.stringify(this.teamScores5));
      localStorage.setItem('individualScores', JSON.stringify(this.individualScores));
    },
    loadScores() {
      const teamScores1 = localStorage.getItem('teamScores1');
      const teamScores2 = localStorage.getItem('teamScores2');
      const teamScores3 = localStorage.getItem('teamScores3');
      const teamScores4 = localStorage.getItem('teamScores4');
      const teamScores5 = localStorage.getItem('teamScores5');
      const individualScores = localStorage.getItem('individualScores');
      if (teamScores1) this.teamScores1 = JSON.parse(teamScores1);
      if (teamScores2) this.teamScores2 = JSON.parse(teamScores2);
      if (teamScores3) this.teamScores3 = JSON.parse(teamScores3);
      if (teamScores4) this.teamScores4 = JSON.parse(teamScores4);
      if (teamScores5) this.teamScores5 = JSON.parse(teamScores5);
      if (individualScores) this.individualScores = JSON.parse(individualScores);
    },
    removeScore(index, type) {
      if (type === 'team1') {
        this.teamScores1.splice(index, 1);
      } else if (type === 'team2') {
        this.teamScores2.splice(index, 1);
      } else if (type === 'team3') {
        this.teamScores3.splice(index, 1);
      } else if (type === 'team4') {
        this.teamScores4.splice(index, 1);
      } else if (type === 'team5') {
        this.teamScores5.splice(index, 1);
      } else if (type === 'individual') {
        this.individualScores.splice(index, 1);
      }
      this.saveScores();
      
    },
  },
  mounted() {
    this.loadScores();
  },
};
</script>

<style scoped>
.radio-group {
  display: flex;
  gap: 20px;
}

.radio-group label {
  font-size: 18px;
}

.radio-group input[type="radio"] {
  transform: scale(1.5); /* 放大单选按钮 */
  margin-right: 10px;
}
.log-details {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-top: 10px;
}
.collapse-header {
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.time-settings-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.time-settings-table th,
.time-settings-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.time-settings-table th {
  background-color: #f2f2f2;
  color: black;
}

.time-settings-table .input-field {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.time-settings-table .button-group {
  display: flex;
  justify-content: space-between;
}

.time-settings-table .btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin: 5px;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}
.reset-btn{
  background-color:red;
  color: white;
}

.btn:disabled {
  background-color: gray;
  cursor: not-allowed;
  color: #ccc;
}
.input-form {
  margin-bottom: 20px;
}

.score-row {
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.btn {
  padding: 10px 20px;
  margin-top: 5px;
}

.add-btn {
  background-color: #4CAF50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.scores-container {
  display: flex;
  gap: 20px;
}

.team-scores {
  width: 58%;
}
.individual-scores {
  width: 38%;
}
.team-scores h2, .individual-scores h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}


.scores-table {
  width: 100%;
  border-collapse: collapse;
}

.scores-table th, .scores-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: right;  /* 设置文字右对齐 */
}

.scores-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.time-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.large-checkbox {
  width: 20px;
  height: 20px;
  transform: scale(1.5);
}

.deductions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.deductions-table th,
.deductions-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.deductions-table th {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #333;
}

.deductions-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.container {
  max-width: 1924px;
  margin: 0 auto;
  padding: 20px;
}
</style>
