import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import InputScores from '../views/InputScores.vue'
import InputScores2 from '../views/InputScores2.vue'
import ScoreRankings from '../views/ScoreRankings.vue'
import ScoreRankings2 from '../views/ScoreRankings2.vue'
import CurrentSequence from '../views/CurrentSequence.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/input-scores' 
  },
  {
    path: '/input-scores',
    name: 'InputScores',
    component: InputScores
  },
  {
    path: '/input-scores2',
    name: 'InputScores2',
    component: InputScores2
  },
  {
    path: '/rankings',
      name: 'ScoreRankings',
      component: ScoreRankings,
      props: route => ({
        individualScores: route.params.individualScores,
        teamScores: route.params.teamScores
      })
  },
  {
    path: '/sequence',
    name: 'CurrentSequence',
    component: CurrentSequence
  },
  {
    path: '/rankings2',
      name: 'ScoreRankings2',
      component: ScoreRankings2,
      props: route => ({
        individualScores: route.params.individualScores,
        teamScores: route.params.teamScores
      })
  }
]

const router = new VueRouter({
  routes
})

export default router
